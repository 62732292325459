<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="充值配置" name="1">
                    <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" label-width="120px" label-position="left" label-suffix=":" size="large">
                        <!-- <el-form-item label="待付款订单取消时间" prop="orderExpireTime">
                    <el-input type="text" auto-complete="off" v-model="form.orderExpireTime" style="width: 100px">
                    </el-input>
                    <span class="desc">分钟内未付款，自动取消订单</span>
                        </el-form-item>-->
                        <!-- <el-form-item label="订单自动确认时间" prop="orderAutoConfirmTime">
                    <el-input type="text" auto-complete="off" v-model="form.orderAutoConfirmTime" style="width: 100px">
                    </el-input>
                    <span class="desc">天，自动确认到货</span>
                        </el-form-item>-->
                        <!-- <el-form> -->
                        <!-- <div> -->
                        <el-form-item prop="close" label="充值配置">
                            <el-switch style="margin-bottom:10px" v-model="form.close" :active-value="2" :inactive-value="0" @change="changeValue(form)"></el-switch>
                        </el-form-item>

                        <el-form-item label="最大充值额度" prop="maximum">
                            <el-input v-model.number="form.maximum" placeholder="输入额度"></el-input>
                        </el-form-item>
                        <el-form-item label="最小充值额度" prop="minimum">
                            <el-input v-model.number="form.minimum" placeholder="输入额度"></el-input>
                        </el-form-item>
                        <el-form-item label="微信充值" prop="weixin" class="inline" label-width="90px">
                            <el-switch :active-value="2" :inactive-value="0" v-model="form.weixin" @change="changeFValue(form.weixin,form)"></el-switch>
                        </el-form-item>
                        <el-form-item label="支付宝充值" prop="zfb" class="inline" label-width="90px">
                            <el-switch :active-value="2" :inactive-value="0" v-model="form.zfb" @change="changeFValue(form.zfb,form)"></el-switch>
                        </el-form-item>
                        <el-form-item label="余额充值" prop="qianbao" class="inline" label-width="90px">
                            <el-switch :active-value="2" :inactive-value="0" v-model="form.qianbao" @change="changeFValue(form.qianbao,form)"></el-switch>
                        </el-form-item>
                        <el-form-item :active-value="2" label="银联充值" prop="bank" class="inline" label-width="90px">
                            <el-switch v-model="form.bank" :active-value="2" :inactive-value="0" @change="changeFValue(form.bank,form)"></el-switch>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="提现配置" name="2">
                    <el-form class="form-box" ref="forms" v-bind:rules="rules" v-bind:model="forms" label-position="left" label-width="120px" label-suffix=":" size="large">
                        <el-form-item prop="close" label="提现配置">
                            <el-switch style="margin-bottom:10px" v-model="forms.close" :active-value="2" :inactive-value="0" @change="changeValue(forms)"></el-switch>
                        </el-form-item>
                        <el-form-item label="最大提现额度" prop="maximum">
                            <el-input v-model.number="forms.maximum" placeholder="输入额度"></el-input>
                        </el-form-item>
                        <el-form-item label="最小提现额度" prop="minimum">
                            <el-input v-model.number="forms.minimum" placeholder="输入额度"></el-input>
                        </el-form-item>
                        <el-form-item label="提现到微信" prop="weixin" class="inline" label-width="90px">
                            <el-switch v-model="forms.weixin" :active-value="2" :inactive-value="0" @change="changeFValue(forms.weixin,forms)"></el-switch>
                        </el-form-item>
                        <el-form-item label="提现到支付宝" prop="zfb" class="inline" label-width="100px">
                            <el-switch v-model="forms.zfb" :active-value="2" :inactive-value="0" @change="changeFValue(forms.zfb,forms)"></el-switch>
                        </el-form-item>
                        <el-form-item label="提现到余额" prop="qianbao" class="inline" label-width="90px">
                            <el-switch v-model="forms.qianbao" :active-value="2" :inactive-value="0" @change="changeFValue(forms.qianbao,forms)"></el-switch>
                        </el-form-item>
                        <el-form-item label="提现到银联" prop="bank" class="inline" label-width="90px">
                            <el-switch v-model="forms.bank" :active-value="2" :inactive-value="0" @change="changeFValue(forms.bank,forms)"></el-switch>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForms">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="支付配置" name="3">
                    <el-form class="form-box" ref="formss" v-bind:rules="rules" v-bind:model="formss" label-width="90px" label-position="left" label-suffix=":" size="large">
                        <el-form-item prop="close" label="支付配置">
                            <el-switch v-model="formss.close" :active-value="2" :inactive-value="0" @change="changeValue(formss)"></el-switch>
                        </el-form-item>
                        <el-form-item label="微信支付" prop="weixin" class="inline">
                            <el-switch v-model="formss.weixin" :active-value="2" :inactive-value="0" @change="changeFValue(formss.weixin,formss)"></el-switch>
                        </el-form-item>
                        <el-form-item label="支付宝支付" prop="zfb" class="inline">
                            <el-switch v-model="formss.zfb" :active-value="2" :inactive-value="0" @change="changeFValue(formss.zfb,formss)"></el-switch>
                        </el-form-item>
                        <el-form-item label="余额支付" prop="qianbao" class="inline">
                            <el-switch v-model="formss.qianbao" :active-value="2" :inactive-value="0" @change="changeFValue(formss.qianbao,formss)"></el-switch>
                        </el-form-item>
                        <el-form-item label="银联支付" prop="bank" class="inline">
                            <el-switch v-model="formss.bank" :active-value="2" :inactive-value="0" @change="changeFValue(formss.bank,formss)"></el-switch>
                        </el-form-item>
                        <!-- <el-form-item label="库存不足" prop="warnSize" class="money">
                    <span class="desc" style="margin-left: 0; margin-right: 10px;">我的收入满</span>
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 0; margin-right: 10px;" class="desc">元时，才能发起提现最高提现金额</span>
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                </el-form-item>
                <el-form-item label="默认手续费" prop="warnSize" class="money">
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">%</span>
                </el-form-item>
                <el-form-item label="提现大于" prop="warnSize" class="money">
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">元，手续费</span>
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">%</span>
                </el-form-item>
                <el-form-item label="提现大于" prop="warnSize" class="money">
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">元，手续费</span>
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">%</span>
                    <span class="icon_box" @click="addInput">
                        <i class="el-icon-plus"></i>
                    </span>
                </el-form-item>
                <div>
                    <p style="display:inline-block;margin-right:10px">充值配置</p>
                    <el-switch v-model="value"></el-switch>
                </div>
                <el-form-item label="最低充值" prop="warnSize" class="money">
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">元，最高充值</span>
                    <el-input type="text" auto-complete="off" v-model="form.warnSize"></el-input>
                    <span style="margin-left: 10px; margin-right: 0px;" class="desc">元</span>
                        </el-form-item>-->
                        <el-form-item>
                            <el-button type="primary" @click="submitFormss">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <!-- <el-tab-pane label="佣金配置" name="4">
                    <el-form class="form-box" ref="commissionForm" v-bind:rules="rules" v-bind:model="commissionForm" label-position="left" label-suffix=":" size="large">
                      
                        <el-form-item label="店员分佣" prop="clerk_commission">
                            <el-input v-model="commissionForm.clerk_commission" placeholder="输入店员分佣"></el-input>
                        </el-form-item>
                        <el-form-item label="店主分佣" prop="shopkeeper_commission">
                            <el-input v-model="commissionForm.shopkeeper_commission" placeholder="输入店主分佣"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitCommissionForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane> -->
                <el-tab-pane label="交易设置" name="5">
                    <el-form class="form-box trade-form" ref="tradeForm" v-bind:rules="rules" v-bind:model="tradeForm" label-position="left" label-suffix=":" size="large" label-width="180px">
                        <el-form-item label="售后" prop="aftersales">
                            <el-input v-model.number="tradeForm.aftersales" placeholder="输入售后时间">
                            </el-input>
                            <span style="margin-left:10px">天内可维权</span>
                        </el-form-item>
                        <el-form-item label="自动签收" prop="receipt">
                            <el-input v-model.number="tradeForm.receipt" placeholder="输入自动签收时间"></el-input>
                            <span style="margin-left:10px">天后自动签收</span>
                        </el-form-item>
                        <el-form-item label="佣金配置" prop="commission">
                            <span style="margin-right:10px">签收后</span>
                            <el-input v-model.number="tradeForm.commission" placeholder="输入佣金配置"></el-input>
                            <span style="margin-left:10px">天内发放佣金</span>
                        </el-form-item>
                        <el-form-item label="取消订单配置" prop="cancel">
                            <span style="margin-right:10px">下单后</span>
                            <el-input v-model.number="tradeForm.cancel" placeholder="输入时间"></el-input>
                            <span style="margin-left:10px">分钟后自动取消订单</span>
                        </el-form-item>
                        <el-form-item label="仅企业用户可以购买" prop="cancel">
                            <el-switch
                                v-model="tradeForm.enterprise_user_buy_only"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                active-text="仅企业用户"
                                inactive-text="不限制"
                            >
                            </el-switch>
                            <span style="margin-left:10px">限制后,若用户关联的企业已下线，或超出购买时间，将不能继续购买</span>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitTradeForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="商城设置" name="6">
                    <el-form class="form-box" ref="storeForm" v-bind:rules="rules" v-bind:model="storeForm" label-position="left" label-suffix=":" size="large" label-width="140px">
                        <el-form-item label="C端域名" prop="c_domain">
                            <el-input v-model="storeForm.c_domain" placeholder="输入c端域名"></el-input>
                        </el-form-item>
                        <el-form-item label="B端域名" prop="b_domain">
                            <el-input v-model="storeForm.b_domain" placeholder="输入B端域名"></el-input>
                        </el-form-item>
                        <el-form-item label="是否开启私域" prop="private_domain">
                            <el-radio-group v-model="storeForm.private_domain">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否开启诸葛埋点" prop="is_zgbury">
                            <el-radio-group v-model="storeForm.is_zgbury">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="是否开启仁联提现" prop="is_renlian">
                            <el-radio-group v-model="storeForm.is_renlian">
                                <el-radio :label="2">是</el-radio>
                                <el-radio :label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitStoreForm">保 存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import { request } from '@/common/request';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb
    },
    data() {
        return {
            activeName: '1',
            pageInfo: {

            },
            menuList: ['设置', '交易设置'],
            loading: false,
            value: true,
            rules: {
                maximum: [{
                    required: true,
                    message: '额度不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: /^[0-9]*[1-9][0-9]*$/,
                    message: "只能输入整数或小数点后两位数字",
                    type: "number",
                }],
                minimum: [{
                    required: true,
                    message: '额度不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: /^[1-9]+\d*(\.\d{0,2})?$|^0?\.\d{0,2}$/,
                    message: "只能输入整数或小数点后两位数字",
                    type: "number",
                }],
                clerk_commission: [{
                    required: true,
                    message: '店员分佣不能为空',
                    trigger: 'blur'
                }, {
                    message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/,
                    type: "number",
                }],
                shopkeeper_commission: [{
                    required: true,
                    message: '店主佣不能为空',
                    trigger: 'blur'
                }, {
                    message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/,
                    type: "number",
                }],
                aftersales: [{
                    required: true,
                    message: '售后设置不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字",
                }],
                receipt: [{
                    required: true,
                    message: '自动收货不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字",
                    type: "number",
                }],
                commission: [{
                    required: true,
                    message: '佣金配置不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字",
                    type: "number",
                }],
                cancel: [{
                    required: true,
                    message: '时间不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "请输入数字",
                    type: "number",
                }],
                c_domain: [{
                    required: true,
                    message: 'C端域名不能为空',
                    trigger: 'blur'
                }],
                b_domain: [{
                    required: true,
                    message: 'B端域名不能为空',
                    trigger: 'blur'
                }],
                private_domain: [{
                    required: true,
                    message: '是否开启私域不能为空',
                    trigger: 'blur'
                }],
                is_zgbury: [{
                    required: true,
                    message: '是否开启诸葛埋点不能为空',
                    trigger: 'blur'
                }],
                is_renlian: [{
                    required: true,
                    message: '是否开启仁联提现不能为空',
                    trigger: 'blur'
                }],
            },
            form: {
                close: 0,
                weixin: 0,
                zfb: 0,
                qianbao: 0,
                bank: 0,
                maximum: 0,
                minimum: 0,
            },
            forms: {
                close: 0,
                weixin: 0,
                zfb: 0,
                qianbao: 0,
                bank: 0,
                maximum: 0,
                minimum: 0,
            },
            formss: {
                close: 0,
                weixin: 0,
                zfb: 0,
                qianbao: 0,
                bank: 0,

            },
            storeForm: {
                // close:2,
                // weixin:0,
                // zfb:0,
                // qianbao:0,
                // bank:0
            },
            tradeForm: {
                b_domain: '',
                c_domain: '',
                is_renlian: 0,
                is_zgbury: 0,
                private_domain: 0,
                enterprise_user_buy_only:false,
                enterprise_user_buy_enable:false,
            },
            commissionForm: {
                clerk_commission: 0,
                shopkeeper_commission: 0

            }
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        // tab切换
        handleClick(tab) {
            switch (tab.paneName) {
                case '1':
                    this.loadData()
                    break
                case '2':
                    this.loadDatas()
                    break
                case '3':
                    this.loadDatass()
                    break
                case '4':
                    this.loadCommission()
                    break
                case '5':
                    this.loadTrade()
                    break
                case '6':
                    this.loadStoreForm()
                    break
            }
        },
        // 添加手续费输入框
        addInput() {

        },
        loadData() {
            this.loading = true;
            request.get('/system/recharge').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    let aa = ret.data.config
                    if (Object.keys(ret.data).length > 0) {
                        this.form = ret.data.config;
                    }

                    this.$refs.form.resetFields()

                } else {
                    this.$message.error(ret.msg);
                }
            });

        },
        loadDatas() {
            this.loading = true;
            request.get('/system/withdrawal').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    console.log(Object.keys(ret.data).length)
                    if (Object.keys(ret.data).length > 0) {
                        this.forms = ret.data.config;
                    }

                    this.$refs.forms.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadDatass() {
            this.loading = true;
            request.get('/system/pay').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    if (Object.keys(ret.data).length > 0) {
                        this.formss = ret.data.config;
                    }
                    this.$refs.formss.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取佣金配置
        loadCommission() {
            this.loading = true;
            request.get('/system/commission/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.commissionForm = ret.data;
                    this.$refs.commissionForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取交易配置
        loadTrade() {
            this.loading = true;
            request.get('/system/transaction/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.tradeForm = ret.data;
                    this.$refs.tradeForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 获取商城配置

        loadStoreForm() {
            this.loading = true;
            request.get('/system/shop/list').then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.storeForm = ret.data;
                    this.$refs.storeForm.resetFields()
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        changeValue(aa) {
            console.log(aa);
            if (!aa.close) {
                aa.weixin = 0
                aa.zfb = 0
                aa.qianbao = 0
                aa.bank = 0
            }
        },
        changeFValue(a, b) {
            if (a) {
                b.close = 2
            }
            if (!b.weixin && !b.zfb && !b.qianbao && !b.bank) {
                b.close = 0
            }
        },

        // 保存充值配置
        submitForm() {
            this.loading = true;
            let _this = this
            request.post('/system/recharge/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    _this.loadData()
                    this.$refs.form.clearValidate()
                    this.$message.success('充值配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存提现配置
        submitForms() {
            let _this = this
            request.post('/system/withdrawal/edit', this.forms).then(ret => {
                if (ret.code == 1) {
                    _this.loadDatas()
                    this.$refs.forms.clearValidate()
                    this.$message.success('提现配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存支付配置
        submitFormss() {
            let _this = this
            request.post('/system/pay/edit', this.formss).then(ret => {
                if (ret.code == 1) {
                    _this.loadDatass()
                    this.$refs.formss.clearValidate()
                    this.$message.success('支付配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存交易配置
        submitTradeForm() {
            let _this = this
            request.post('/system/transaction/edit', this.tradeForm).then(ret => {
                if (ret.code == 1) {
                    _this.loadTrade()
                    this.$refs.tradeForm.clearValidate()
                    this.$message.success('支付配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 保存商城配置
        submitStoreForm() {
            let _this = this
            request.post('/system/shop/edit', this.storeForm).then(ret => {
                if (ret.code == 1) {
                    _this.loadStoreForm()
                    this.$refs.storeForm.clearValidate()
                    this.$message.success('商城配置保存成功');
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
    },
}

</script>

<style scoped>
.desc {
    margin-left: 10px;
    font-size: 14px;
    color: #606266;
}

.inline {
    display: inline-block;
    width: 25%;
}

.money >>> .el-input {
    width: 100px !important;
}
.trade-form{
    width: 1000px;
}

.icon_box {
    width: 30px;
    height: 30px;
    display: inline-block;
    border: 1px solid #dddddd;
    margin-left: 10px;
    text-align: center;
    line-height: 30px;
}

.el-form-item--large.el-form-item {
    margin-top: 30px;
}
</style>